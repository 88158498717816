import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="LOGIN" />
    <h1>Login page2 alterado e refletido</h1>
    <p>login.</p>
  </Layout>
)
